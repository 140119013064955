import React from "react"
import { useLocation } from "@reach/router"
import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { useStore } from "../context/NewStoreContext"
import { countryLookupName } from "../utils/countries"

const IndexPage = ({ pageContext }) => {
  const { shopstoryContent, contentModules, isNavbarOverlapping, navbarInWhite, seo } = pageContext
  const { cart } = useStore()
  const location = useLocation()

  const [content, setContent] = React.useState({
    shopstoryContent: shopstoryContent,
    contentModules: contentModules,
    isNavbarOverlapping: isNavbarOverlapping,
    navbarInWhite: navbarInWhite,
  })

  React.useEffect(() => {
    const region = countryLookupName(cart?.shipping_address?.country_code)
    const alternative = pageContext.homePageAlternatives.find(i => i.region === region)
    const alternativeContent = alternative?.shopstoryContent

    if (alternative) {
      setContent({
        shopstoryContent: alternativeContent,
        contentModules: [{
          contentful_id: Object.keys(alternative.shopstoryContent)[0],
          internal: {
            type: "ContentfulShopstoryContent"
          }
        }],
        isNavbarOverlapping: alternative.isNavbarOverlapping,
        navbarInWhite: alternative.navbarInWhite,
      })
    } else {
      setContent({
        shopstoryContent,
        contentModules,
        isNavbarOverlapping,
        navbarInWhite,
      })
    }
  }, [cart?.shipping_address?.country_code])

  React.useEffect(() => {
    if (location?.state?.showCart) {
      window.history.replaceState(
        {
          showCart: false,
        },
        document?.title
      )
    }
  }, [])

  return (
    <Layout
      pullUp={content.isNavbarOverlapping}
      navbarInWhite={content.navbarInWhite}
      isHomepage={true}
      showCart={location?.state?.showCart}
    >
      <SEO
        metaImage={seo.metaImageUrl || ""}
        title={seo.pageTitle}
        canonicalUrl={""}
        breadcrumbsList={seo.breadcrumbsList}
        isHomepage={true}
      />
      <ContentModules
        pullUp={content.isNavbarOverlapping}
        allModules={content.contentModules}
        shopstoryContent={content.shopstoryContent}
      />
    </Layout>
  )
}

export default IndexPage
